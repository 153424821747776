<template>
  <div class="login">
    <div class="container login-wrapper">
      <div class="row align-items-center">
        <div class="col-md-5">
          <img src="../assets/login-img.svg" alt="" />
        </div>
        <div class="col-md-7">
          <h3>NEST Registration</h3>
          <form @submit.prevent="register">
            <div class="mb-2">
              <label for="first_name" class="form-lab el">First Name</label>
              <input
                type="text"
                class="form-control"
                id="first_name"
                v-model="firstName"
                aria-describedby="first_name"
              />
              <div
                class="input-errors"
                v-for="(error, errorIndex) of v$.firstName.$errors"
                :key="errorIndex"
              >
                <div class="error-msg">{{ error.$message }}</div>
              </div>
              <div v-if="errors && errors.first_name" class="error-msg">
                {{ errors.first_name[0] }}
              </div>
            </div>
            <div class="mb-2">
              <label for="last_name" class="form-lab el">Last Name</label>
              <input
                type="text"
                class="form-control"
                id="last_name"
                v-model="lastName"
                aria-describedby="emailHelp"
              />
              <div
                class="input-errors"
                v-for="(error, errorIndex) of v$.lastName.$errors"
                :key="errorIndex"
              >
                <div class="error-msg">{{ error.$message }}</div>
              </div>
              <div v-if="errors && errors.last_name" class="error-msg">
                {{ errors.last_name[0] }}
              </div>
            </div>
            <div class="mb-2">
              <label for="email" class="form-lab el">Email</label>
              <input
                type="email"
                class="form-control"
                id="email"
                v-model="email"
                aria-describedby="email"
              />
              <div
                class="input-errors"
                v-for="(error, errorIndex) of v$.email.$errors"
                :key="errorIndex"
              >
                <div class="error-msg">{{ error.$message }}</div>
              </div>
              <div v-if="errors && errors.email" class="error-msg">
                {{ errors.email[0] }}
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-md-2 col-xs-3">
                <label for="phone_number_code" class="form-lab el">Code</label>
                <select
                  class="form-control"
                  v-model="phone_number_code"
                  id="phone_number_code"
                >
                  <option
                    v-for="(country, countryIndex) in countries"
                    :key="countryIndex"
                    :value="country.phone_code"
                  >
                    {{ country.phone_code }}
                  </option>
                </select>
              </div>
              <div class="col-md-10 col-xs-9">
                <label for="phone" class="form-lab el">Phone</label>
                <input
                  type="text"
                  class="form-control"
                  id="phone"
                  v-model="phone"
                  aria-describedby="phone"
                />
                <div
                  class="input-errors"
                  v-for="(error, errorIndex) of v$.phone.$errors"
                  :key="errorIndex"
                >
                  <div class="error-msg">{{ error.$message }}</div>
                </div>
                <div v-if="errors && errors.phone" class="error-msg">
                  {{ errors.phone[0] }}
                </div>
              </div>
            </div>
            <div class="mb-2">
              <label for="standard" class="form-lab el">Standard</label>
              <select class="form-control" v-model="standard" id="standard">
                <option value="">Select Standard</option>
                <option
                  v-for="(standard, standardIndex) in standards"
                  :key="standardIndex"
                  :value="standard.id"
                >
                  {{ standard.title }}
                </option>
              </select>
              <div
                class="input-errors"
                v-for="(error, errorIndex) of v$.standard.$errors"
                :key="errorIndex"
              >
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
            <div class="mb-2">
              <label for="school" class="form-lab el">School</label>
              <select
                class="form-control"
                @change="changeSchool()"
                v-model="school"
                id="school"
              >
                <option value="">Select school</option>
                <option
                  v-for="(school, schoolIndex) in schools"
                  :key="schoolIndex"
                  :value="school.id"
                >
                  {{ school.first_name }}
                </option>
                <option value="0">other</option>
              </select>
              <div
                class="input-errors"
                v-for="(error, errorIndex) of v$.school.$errors"
                :key="errorIndex"
              >
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
            <div v-if="showSchoolName == true" class="mb-2 form-row">
              <div class="col-md-12">
                <label for="school_name" class="form-lab el">School Name</label>
                <input
                  type="text"
                  v-model="schoolName"
                  class="form-control"
                  id="school_name"
                />
              </div>
            </div>
            <div class="mb-2">
              <label for="exampleInputPassword1" class="form-lab el"
                >Password</label
              >
              <input
                type="password"
                class="form-control"
                id="password"
                v-model="password"
              />
              <div
                class="input-errors"
                v-for="(error, errorIndex) of v$.password.$errors"
                :key="errorIndex"
              >
                <div class="error-msg">{{ error.$message }}</div>
              </div>
              <div v-if="errors && errors.password" class="error-msg">
                {{ errors.password[0] }}
              </div>
            </div>
            <div class="mb-2 action-div">
              <a href="#">
                <span
                  >Register with
                  <router-link :to="{ name: 'nestRegisterWithPhone' }"
                    >Phone</router-link
                  ></span
                >
              </a>
              <span
                >Already have an account
                <router-link :to="{ name: 'login', query: { name: 'Nest' } }"
                  >Login</router-link
                ></span
              >
            </div>
            <div class="text-right">
              <button
                type="submit"
                :disabled="
                  this.$store.state.studentAuth.isAuthenticating == true
                "
                class="btn cta-primary"
              >
                <span
                  v-if="this.$store.state.studentAuth.isAuthenticating == false"
                >
                  Register
                </span>
                <span v-else>
                  <circular-loader></circular-loader>
                </span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HomeService from "@/services/HomeService";
import useVuelidate from "@vuelidate/core";
import AuthService from "@/services/AuthService";
import CircularLoader from "@/components/loaders/CircularLoader";
import "bootstrap";
import errorLog from "@/errorLog";
import { required, helpers } from "@vuelidate/validators";
export default {
  setup() {
    return {
      v$: useVuelidate()
    };
  },
  name: "nestRegister",
  components: {
    CircularLoader
  },
  created() {
    this.getStandards();
    this.getSchools();
    this.getCountryCode();
  },
  data() {
    return {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      password: "",
      authStatus: "",
      school: "",
      standard: "",
      phone_number_code: "+91",
      token: "",
      user: "",
      countries: [],
      standards: [],
      schools: [],
      schoolName: "",
      showSchoolName: false,
      register_type: "email",
      errors: {}
    };
  },
  validations() {
    return {
      firstName: {
        required: helpers.withMessage("Please enter your first name", required)
      },
      lastName: {
        required: helpers.withMessage("Please enter your last name", required)
      },
      email: {
        required: helpers.withMessage("Please enter  your email", required)
      },
      phone: {
        required: helpers.withMessage("Please enter your phone", required)
      },
      password: {
        required: helpers.withMessage("Please enter your password", required)
      },
      school: {
        required: helpers.withMessage("Please select school", required)
      },
      standard: {
        required: helpers.withMessage("Please select standard", required)
      }
    };
  },
  methods: {
    register: function() {
      this.v$.$touch();
      if (this.v$.$invalid) {
        return;
      }
      this.$store.dispatch("studentAuth/authRequest", true);
      let first_name = this.firstName;
      let last_name = this.lastName;
      let email = this.email;
      let phone = this.phone;
      let password = this.password;
      let standard_id = this.standard;
      let school_id = this.school;
      let school_name = this.schoolName;
      let register_type = this.register_type;
      let phone_number_code = this.phone_number_code;
      AuthService.nestRegister({
        first_name,
        last_name,
        standard_id,
        school_id,
        email,
        phone,
        register_type,
        school_name,
        phone_number_code,
        password
      })
        .then(result => {
          this.authStatus = result.data.status;
          if (this.authStatus == "error") {
            this.$toast.error(result.data.message);
            this.$store.dispatch("studentAuth/authError");
            this.$store.dispatch("studentAuth/authRequest", false);
          } else if (this.authStatus == "validation_error") {
            this.errors = result.data.message || {};
            this.$store.dispatch("studentAuth/authError");
            this.$store.dispatch("studentAuth/authRequest", false);
          } else {
            this.$toast.success(result.data.message);
            const userData = {
              token: result.data.token,
              user: result.data.user
            };
            this.$store.dispatch("studentAuth/authSuccess", userData);
            this.$store.dispatch("studentAuth/authRequest", false);
            if (this.$store.state.studentAuth.nextUrl == "") {
              this.$router.push({
                name: "dashboardTab"
              });
            } else {
              this.$router.push(this.$store.state.studentAuth.nextUrl);
              this.$store.dispatch("studentAuth/updateNextUrl", "");
            }
          }
        })
        .catch(error => {
          this.error = error;
          this.$store.dispatch("studentAuth/authError");
          this.$store.dispatch("studentAuth/authRequest", false);
        });
    },
    async getStandards() {
      await HomeService.getStandardList()
        .then(result => {
          this.standards = result.data;
        })
        .catch(error => {
          errorLog.log(error);
        });
    },
    changeSchool() {
      this.showSchoolName = this.school == 0 ? true : false;
    },
    async getSchools() {
      await HomeService.getSchools()
        .then(result => {
          this.schools = result.data;
        })
        .catch(error => {
          errorLog.log(error);
        });
    },
    async getCountryCode() {
      await HomeService.getCountryCode()
        .then(result => {
          this.countries = result.data;
        })
        .catch(error => {
          false, errorLog.log(error);
        });
    }
  }
};
</script>

<style lang="scss">
@import "@/style/login.scss";
</style>
